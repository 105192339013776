import { Location,MeetingSpace } from '../utils/interfaces/interfaces';

const API_ENDPOINT = 'https://stagapi.zenspace.io';

export const fetchLocations = async (): Promise<Location[]> => {
  try {
    const response = await fetch(`${API_ENDPOINT}/location-group-meetingroom/`);
    if (!response.ok) {
      throw new Error('Failed to fetch locations');
    }
    const data = await response.json();
    return data.results;
  } catch (error:any) {
    throw new Error(error.message);
  }
};
export const fetchLocationBySlug = async (id: string): Promise<Location> => {
  try {
    const response = await fetch(`${API_ENDPOINT}/location-group-meetingroom/?locationslug=${id}`);
    if (!response.ok) {
      throw new Error('Failed to fetch location');
    }
    const data = await response.json();
    return data.results[0];
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchLocationGroupBySlug = async (id: string, groupSlug:string): Promise<Location> => {
    try {
      const response = await fetch(`${API_ENDPOINT}/location-group-meetingroom/?locationslug=${id}&groupslug=${groupSlug}`);
      if (!response.ok) {
        throw new Error('Failed to fetch location');
      }
      const data = await response.json();
      return data.results[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  };
  export const fetchLocationGroupMeetingBySlug = async (id: string, groupSlug:string, meetingroomSlug:string): Promise<Location> => {
    try {
      const response = await fetch(`${API_ENDPOINT}/location-group-meetingroom/?locationslug=${id}&groupslug=${groupSlug}&meetingroomslug=${meetingroomSlug}`);
      if (!response.ok) {
        throw new Error('Failed to fetch location');
      }
      const data = await response.json();
      return data.results[0];
    } catch (error: any) {
      throw new Error(error.message);
    }
  };
  export const fetchMeetingRoomById = async (id: string): Promise<MeetingSpace> => {
    try {
      const response = await fetch(`${API_ENDPOINT}/meetingroom/${id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch location');
      }
      const data = await response.json();
      console.log(data, 'services')
      return data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  };
  export const createBooking = async (bookingData:any) => {
    try {
      const response = await fetch(`${API_ENDPOINT}/booking/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bookingData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create booking');
      }
  
      const data = await response.json();
      return {
        status: response.status,
        data: data,
      };
    } catch (error:any) {
      throw new Error(error.message);
    }
  };
  export const updateBooking = async (id:string,bookingData?:any) => {
    try {
      const response = await fetch(`${API_ENDPOINT}/booking/${id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify(bookingData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create booking');
      }
  
      const data = await response.json();
      return {
        status: response.status,
        data: data,
      };
    } catch (error:any) {
      throw new Error(error.message);
    }
  };
  export const getBooking = async (id:any) => {
    try {
      const response = await fetch(`${API_ENDPOINT}/booking/${id}`);
  
      if (!response.ok) {
        throw new Error('Failed to get booking');
      }
  
      const data = await response.json();
      return {
        status: response.status,
        data: data,
      };
    } catch (error:any) {
      throw new Error(error.message);
    }
  };

  // doorService.js

// Define your server URL

export const unlockDoorService = async (id:number,key:string) => {
  try {
    console.log(key)
    // Send the unlock request to the server using fetch
    const response = await fetch(`${API_ENDPOINT}/meetingroom/${id}/unlock/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ key: key }),
    });

    if (response.ok) {
      // Parse and return the response data
      const data = await response.json();
      return data;
    } else {
      // Handle non-2xx responses, e.g., throw an error
      throw new Error('Unlock failed');
    }

  } catch (error) {
    // Handle fetch errors, e.g., network issues
    throw error;
  }
};
