import React from 'react';
interface LocationMapProps {
  location: {
    address_prefix: string;
    street: string;
    houseNumber: string;
    city: string;
    // Add any other properties as needed
  };
}

class LocationMap extends React.Component<any> {
  render() {
    const { location } = this.props;
console.log(location, 'locationmap')
    if (!location || !location.street || !location.address_prefix || !location.city) {
      return <div className="location-map">Invalid location data</div>;
    }

    const sanitizedStreet = encodeURIComponent(location.address_prefix);
    const sanitizedHouseNumber = encodeURIComponent(location.street);
    const sanitizedCity = encodeURIComponent(location.city);

    const mapsURL = `https://maps.google.com/maps?q=${sanitizedStreet}%20${sanitizedHouseNumber}%20${sanitizedCity}&key=${'AIzaSyDPXDlbotNnWG1x_E9DXRhZT9KDCKbLqgc'}&t=&z=20&ie=UTF8&iwloc&z=15&output=embed`;

    return (
      <div className="location-map">
        <iframe src={mapsURL} width="100%" height="500" frameBorder="0" title="Location Map"></iframe>
      </div>
    );
  }
}

export default LocationMap;
