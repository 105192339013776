import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface user {
  name: string;
  email: string;
  phone_number: string 
  // Add other properties as needed
}

interface userState {
  data: user[];
  loading: boolean;
  error: string | null;
}

const initialState: userState = {
  data: [] ,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    getUserSuccess(state, action: PayloadAction<user[]>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    getUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getUserStart,
  getUserSuccess,
  getUserFailure,
} = userSlice.actions;

export default userSlice.reducer;
