import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationGroupByIdAction } from '../actions/locations';
import { useParams, Link, useNavigate } from 'react-router-dom';
import MeetingCard from '../components/MeetingCard';
import ShimmerMeetingCardList from '../components/ShimmerMeetingCardList';
import UserDetailsModal from '../components/UserDetailsModal';
import { wrapText } from '../common/commonFunction';
import Tags from '../components/Tags';
import Filters from '../components/Filters';
import SpecificDurationModal from '../components/SpecificDurationModal';
import LocationMap from '../components/LocationMap';

const Group = () => {
  const { locationSlug,groupSlug } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { loading, data: location } = useSelector((state: any) => state.locations);
  const guestDataString = sessionStorage.getItem('guest');
  const [showModal, setShowModal] = useState(false);
  const [getUrl, setUrl] = useState('');
  const [bookingType, setBookingtype]= useState('');
// ... (other code remains the same)

  const closeModal = () => {
    setShowModal(false);
  };
  const handleOptionChange = (option: string) => {
    console.log(option, 'selected option in Group');
    setBookingtype(option);
    // You can perform any necessary actions here
  };
  const handleBookNow = (url:string) => {
    setUrl(url)
    console.log('bookingType:', bookingType); // Log the bookingType value
    if(bookingType !== 'specificDuration'){
    navigate(url+'/?bookingType='+'payAsYouGo'+'&duration=0')
    }else{
      setShowModal(true);
  }
    //openModal(); // Show the modal
  };
  useEffect(() => {
    if (groupSlug) {
      const groupId = groupSlug; // Convert id to a number
      dispatch(fetchLocationGroupByIdAction({ locationSlug: locationSlug ?? '', groupSlug }) as any);
    }
  }, [groupSlug]);

  return (
    <>
    <div className="container content-body">
      <div className='row mt-4'>
        <div className='col-md-8'>
      <div className='zen-page-heading'>
          <h1>{wrapText(location?.name,30)}</h1>
          <h3 className='mt-1'>{location?.groups[0]?.name}</h3>
          <span className='zen-address-text'>{location?.venue_details?.address?.address_prefix},{location?.venue_details?.address?.street},{location?.venue_details?.address?.city},{location?.venue_details?.address?.state},{location?.venue_details?.address?.postal_code},{location?.venue_details?.address?.country}</span>
        </div>
        <div className='row mt-5'>
        <div className='col-md-4'><Tags name="Available on Weekends"/></div>
        <div className='col-md-4'><Tags name="Late Night Hours"/></div>
        </div>
        <div className='row mt-5'>
        {/* Show three images on md and above */}
        {['col-md-4', 'col-md-4', 'col-md-4'].map((colClass, index) => (
          <div key={index} className={`${colClass} d-none d-sm-block`}>
            {index < 3 && (
              <img className='zen-group-detail-image img-fluid' src={location?.groups[0]?.banner_detail?.card_image} alt={`Image ${index + 1}`} />
            )}
          </div>
        ))}

        {/* Show only one image on sm and xs screens */}
        <div className='col-sm-12 col-xs-12 d-md-none d-lg-none d-xl-none'>
          <img className='zen-group-detail-image img-fluid' src={location?.groups[0]?.banner_detail?.card_image} alt="Image for sm and xs screens" />
        </div>
      </div>
        </div>
        <div className='col-md-4 d-none d-sm-block'>
        {/* <img className='zen-group-map-image img-fluid mt-5' src="/images/map-view.svg"/> */}
       <LocationMap location={location?.venue_details?.address}/>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='zen-page-heading'>
        <h2>Meeting Spaces</h2>
        </div>
        {/* <Filters/> */}

        <div className='col-md-12'>
          <br/><br/>
      {loading ? (
          // Render shimmer cards when data is loading
            <ShimmerMeetingCardList length={3} />   
                     ) : (
          // Render actual data when loaded
          location?.groups[0]?.meeting_rooms?.map((meeting_room:any) => (
            <div className="" key={meeting_room.id}>

              <MeetingCard 
              title={meeting_room.name} 
              address={location.venue_details.address} 
              card_image={meeting_room.banner_detail.card_image} 
              amenities = {meeting_room.amenities}
              url={`/${location.access.url_slug}/${location.groups[0].access.url_slug}/${meeting_room.access.url_slug}`} 
              type={meeting_room.type}
              status={meeting_room.status}
              pricing={meeting_room.pricing}
              onBookNowClick={handleBookNow} // Pass the handler and the url
              onOptionChange={handleOptionChange} // Pass the option change handler

              />
            
            </div>
          ))
        )} 
</div>

      </div>
    </div>
      <SpecificDurationModal isOpen={showModal} closeModal={closeModal} url={getUrl} />
    </>
      );
    
};

export default Group;
