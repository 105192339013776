import React from 'react';

// Define a TypeScript interface for the props
interface TagsProps {
  name: string;
}

const Tags: React.FC<TagsProps> = ({ name }) => {
  return (
    <a href='#' className="nav-link zen-tag-tab zen-tag-text active" style={{cursor: 'none'}}>
        <span className="zen-tag-text active ms-4">{name}</span>
      </a>

  );
};

export default Tags;
