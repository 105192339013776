import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../components/PaymentForm';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMeetingSpaceByIdAction } from '../actions/meetingRoom';
import BookingSummary from '../components/BookingSummary';
const stripePromise = loadStripe('pk_live_CEKEZh124pleOeee47rAXnGB');

const Payment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const path = useLocation();
  const queryParams = new URLSearchParams(path.search);
  const bookingType = queryParams.get('bookingType');

  const { loading, data } = useSelector((state: any) => state.meetingRooms);
  useEffect(() => {
    if (id) {
      dispatch(fetchMeetingSpaceByIdAction({ meetingroomId: id ?? '' }) as any);
    }
  }, [id]);
  return (
    <div className="container content-body">
    <div className='row'>
      <div className='col-md-8'>

        <div className='row'>
          <div className='col-md-9 mx-auto'>
            <div className='zen-page-heading mt-5'>
              <h2>Complete your Reservation</h2>
              </div>
              <div>
                {/* Wrap the PaymentForm component with Elements */}
                <Elements stripe={stripePromise}>
                  <PaymentForm id={id}/>
                </Elements>
              </div>
              </div>
              </div>
              </div>
              <div className='col-md-4'>
              <BookingSummary data={data} bookingType={bookingType ? bookingType : 'payAsYouGo'}/>

                </div>
                </div>


    </div>
  );
};

export default Payment;
