import React, { useEffect, useRef, useState } from 'react';
import { getBookingAction, updateBookingAction } from '../actions/reservation';
import { useParams, useNavigate } from 'react-router-dom';
import { Calendar2CheckFill } from 'react-bootstrap-icons';
import { fetchMeetingSpaceByIdAction } from '../actions/meetingRoom';
import { BookingData } from '../utils/interfaces/interfaces';
import { formatTimestamp, getCurrentTimeInTimeZone, getCurrentTimeInTimeZoneForApi } from '../common/commonFunction';
import BookingSummary from '../components/BookingSummary';
import { useDispatch, useSelector } from 'react-redux';
import BookingSummarySuccess from '../components/BookingSummarySuccess';
import SlidingCheckbox from '../components/slidingCheckbox';
import Loader from '../components/Loader';
import Timer from '../components/Timer';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
const ReservationSuccess = () => {
  const timerRef = useRef(null);
  const { id } = useParams();
  const [bookingData, setBookingData] = useState<BookingData | null>(null); // Initialize as null
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state: any) => state.meetingRooms);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading]= useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
    setIsLoading(true);
      if (id) {
        try {
          const response = await getBookingAction(id);
if(response){
          setBookingData(response.data)
          setIsLoading(false)
 } // Handle the response here
        } catch (error) {
          setIsLoading(false)
          console.error(error);
          // Handle error here
        }
      }
    };

    fetchData(); // Call the async function
    const handleBackButton = (event:any) => {
      event.preventDefault();
      return false;
    };

    // Disable the back button
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      // Re-enable the back button when the component is unmounted
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [id]);
  const handleCloseConfirmation = () => setShowConfirmation(false);
  const handleShowConfirmation = () => setShowConfirmation(true);

  const checkout = async () => {
    // Display confirmation modal before proceeding with checkout
    handleShowConfirmation();
  };

  const confirmCheckout = async () => {
    setIsLoading(true);

    // Close the confirmation modal
    handleCloseConfirmation();

    // Your existing checkout logic goes here
    const data = {
      end_time: bookingData && getCurrentTimeInTimeZoneForApi(bookingData?.meeting_room?.timezone),
      status: 'completed',
    };

    if (id) {
      try {
        const response = await updateBookingAction(id);
        console.log(response, 'response');

        if (response.status === 200) {
          setIsLoading(false);

          toast.success('Checked out successfully');
          setBookingData(response.data);
        } else {
          setIsLoading(false);

          toast.error('Booking checkout failed. Please try again.');
        }
      } catch (error) {
        setIsLoading(false);

        console.error(error);
      }
    }
  };


  
  return (
    <div className="container content-body">
      {loading || isLoading ? <Loader/> :
       (<div className='row'>
        <div className='col-md-8'>
<div className='row'>
  <div className='col-md-9 text-center'>
<div className='zen-page-heading mt-2'>
                <h2 className='mt-5'>Booked Succesfully</h2>
                       </div>
              <div className='mt-5'>
                <img src="/images/successcheck.svg"/>
              </div>
              {bookingData?.status !== 'completed' &&
              <div className='mt-5 text-center'>
          {bookingData &&
          <SlidingCheckbox id={bookingData?.meeting_room?.id} unlockKey={bookingData?.unlock_key} />
          }
          <div className='zen-page-heading mt-4'>
                <h4 className=''>Toggle to unlock a space</h4>
                
              </div>
              <div>
              <div className='zen-success-heading'>
                <h4 className=''>Unlock Code:{bookingData?.unlock_key}</h4>
                
              </div>
                </div>             
               </div>
}
{bookingData?.status === 'on_going'&& bookingData?.type ===1  &&(
  
  <div className='mt-2'>
    <Timer basePrice={bookingData?.meeting_room?.pricing?.price_per_minute} startTime={bookingData?.start_time} timeZone={bookingData?.meeting_room?.timezone}
/>
    <button
                onClick={() => checkout()}
                className="btn btn-outline-primary mt-5 zen-book-btn"
                style={{ marginBottom: '3%' }}
              >
                <h4>
                Checkout
                </h4>
              </button>
                {/* <a className='btn btn-outline-primary mt-5' onClick={checkout} >Checkout</a> */}
              </div>)||
              (          <div className='mt-2'>
              <a className='btn btn-outline-primary mt-2' target='_blank' href={bookingData?.receipt_url}>View Receipt</a>
            </div>)}
              
</div>
</div>
        </div>
        <div className='col-md-4 mt-4'>
          <BookingSummarySuccess data={bookingData} />

        </div>
      </div>)}
      {showConfirmation && (
                  <Modal show={true} onHide={handleCloseConfirmation}>
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm Checkout</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Please make sure the meeting room is empty.</p>
                    <p>Close the door properly before confirming.</p>
                    <p>A minimum deduction of 15 minutes will apply.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmation}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmCheckout}>
                      Confirm Checkout
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

    </div>
    
  );
};

export default ReservationSuccess;
