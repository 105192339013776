import React from 'react';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light zen-header">
    <div className="container">
      <a className="navbar-brand" href="/">
        <img src="/images/zenspace-logo.svg" alt="Logo" />
      </a>
    </div>
  </nav>

  );
};

export default Header;
