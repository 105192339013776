export function getMeetingRoomType(number: number): string {
    switch (number) {
      case 1:
        return 'DESK';
      case 2:
        return 'BOOTH';
      case 3:
        return 'CONFERENCE';
      default:
        return 'UNKNOWN';
    }
  }
  
  export function getMeetingRoomStatus(number: number): string {
    switch (number) {
      case 1:
        return '<span style="color: red;">In-Use</span>';
      case 2:
        return '<span style="color: green;">Available</span>';
      case 3:
        return '<span style="color: orange;">Unavailable</span>';
      default:
        return 'UNKNOWN';
    }
  }
  
  export function getTimestampFromTimeStringWithTimeZone(timeString: string): number | null {
    try {
      console.log(timeString, 'timeString');
      const timestamp1 = new Date(timeString).getTime()// Convert milliseconds to seconds

console.log(timestamp1);
      const dateParts = timeString.split(/[-T:+]/);
      
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);
      const hour = parseInt(dateParts[3], 10);
      const minute = parseInt(dateParts[4], 10);
      const second = parseInt(dateParts[5], 10);
  
      const timezoneOffset = parseInt(dateParts[6], 10) * 60 + parseInt(dateParts[7], 10);
      console.log(timezoneOffset, 'timezoneOffset')
      
      const date = new Date(Date.UTC(year, month, day, hour, minute, second) - timezoneOffset * 60000);
      const timestamp = date.getTime(); // timestamp already in milliseconds
  
      return timestamp1;
    } catch (error) {
      console.error('Error parsing startTime:', error);
      return null;
    }
  }
  
  
  export function convertTimeToISOString(inputTime: string) {
    const currentDate = new Date(); // Get the current date
  
    // Split the input time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = inputTime.split(':');
  
    // Set the time on the current date
    currentDate.setHours(parseInt(hours, 10));
    currentDate.setMinutes(parseInt(minutes, 10));
    currentDate.setSeconds(parseInt(seconds, 10));
  
    // Format the date in ISO string format
    const formattedDate = currentDate.toISOString();
  
    return formattedDate;
  }
  
  export function formatTimestamp(timestamp: any) {
    const formattedDate = new Date(timestamp).toLocaleString();
    return formattedDate;
  }
  
  export function wrapText(text: string, maxCharacters: number): string {
    if (text?.length <= maxCharacters) {
      return text;
    }
  
    return text?.slice(0, maxCharacters) + '...';
  }
  export function getCurrentTimestampInTimezone(timezone: any): number {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    };
  
    const formatter = new Intl.DateTimeFormat('en-US', options);
  
    const dateParts: Record<string, string> = {};
    formatter.formatToParts(new Date()).forEach((part) => {
      dateParts[part.type] = part.value;
    });
  
    const timestamp = Date.parse(`${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}`);
  
    return timestamp;
  }
  
  
  
  
  export function getCurrentTimeInTimeZone(timezone: string) {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
  
    return new Intl.DateTimeFormat("en-US", options).format(now);
  }
  export function getCurrentTimeInTimeZoneForApi(timezone: string) {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(now);
    const formattedDateTimeWithHyphens = formattedDateTime.replace(/\//g, '-');
    const results = formattedDateTimeWithHyphens.replace(/,/g, '');
    return results;
  }
  
  export function formatDateAndTime(dateTimeString: any) {
    const dateTime = new Date(dateTimeString);
    if (isNaN(dateTime.getTime())) {
      return null;
    }
  
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };
    
    const formattedDate = dateTime.toLocaleDateString('en-US', dateOptions);
    const formattedTime = dateTime.toLocaleTimeString('en-US', timeOptions);
  
    return { date: formattedDate, time: formattedTime };
  }

  export function calculateDuration(start_time: string, end_time: string) {
    const startTime = new Date(start_time);
    const endTime = new Date(end_time);
  
    if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
      return null;
    }
  
    const timeDifferenceMs = endTime.getTime() - startTime.getTime();
    const minutes = Math.floor(timeDifferenceMs / (1000 * 60));
  
    return minutes;
  }
    