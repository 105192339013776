import React from 'react';
import Amenities from './Ameneties';
import ShimmerAmenities from './ShimmerAmeneties';
import ShimmerCardList from './ShimmerCardList';

const ShimmerLocationDetail = () => {
  return (
    <div className="container content-body">
      <div className="shimmer-banner"></div>
      <br/>
        <div className="shimmer-title"></div>
        <br/>
        <div className="shimmer-line"></div>
        <br/>
        <ShimmerAmenities count={4} />
        <br/>
        <h2>Groups:</h2>
        <ShimmerCardList length={3} />
    </div>
  );
};

export default ShimmerLocationDetail;
