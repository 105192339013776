import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMeetingRoomById, unlockDoorService } from '../services/locationServices';
import {  fetchMeetingSpacesStart, fetchMeetingSpacesSuccess, fetchMeetingSpacesFailure } from '../utils/slices/meetingSpaceSlice';

export const fetchMeetingSpaceByIdAction = createAsyncThunk(
    'meetingspace/id',
    async ({ meetingroomId }: {  meetingroomId: string }, { dispatch }) => {
      try {
        dispatch(fetchMeetingSpacesStart());
        const meetingspace = await fetchMeetingRoomById(meetingroomId);
        console.log(meetingspace, 'actions')
        dispatch(fetchMeetingSpacesSuccess(meetingspace));
      } catch (error: any) {
        dispatch(fetchMeetingSpacesFailure(error.message));
      }
    }
  );

  // doorActions.js

  export const unlockDoor = async (id: number, key: string) => {
    try {
      console.log(key, 'action')
      const response = await unlockDoorService(id, key);
  
      // Handle the response here if needed
      console.log('Unlock response:', response);
  
      // Dispatch any actions or handle state updates as needed
      // Example: dispatch({ type: 'DOOR_UNLOCKED', payload: response });
  
      return response; // Return the response so you can use .then() on it
    } catch (error) {
      // Handle errors, e.g., show an error message to the user
      console.error('Unlock error:', error);
      // Example: dispatch({ type: 'UNLOCK_ERROR', payload: error });
  
      throw error; // Re-throw the error for further handling if needed
    }
  };
  
// You can create additional action types and action creators as needed
