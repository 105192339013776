import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Group {
  id: number;
  name: string;
  // Add other properties as needed
}

interface GroupsState {
  data: Group[];
  loading: boolean;
  error: string | null;
}

const initialState: GroupsState = {
  data: [],
  loading: false,
  error: null,
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    fetchGroupsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchGroupsSucess(state, action: PayloadAction<Group[]>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchGroupsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchGroupsStart,
  fetchGroupsSucess,
  fetchGroupsFailure,
} = groupsSlice.actions;

export default groupsSlice.reducer;
