import React from 'react';

interface Amenity {
  id: number;
  name: string;
  description: string;
  is_active: boolean;
}

interface AmenitiesProps {
  amenities: Amenity[];
}

const Amenities = ({ amenities }: AmenitiesProps) => {
  return (
    <div className="amenities">
      <div className='zen-page-heading'>
        <h2>Amenities</h2>
      </div>
      <ul className="list-group list-group-horizontal">
        {amenities?.map((amenity, index) => (
          <React.Fragment key={amenity.id}>
            <li className="list-group-item zen-ameneties">
              <div className="d-flex flex-column align-items-center text-center">
                <img className='zen-amenity-icon' src={`/images/${amenity.name}.svg`} />
                <span className="zen-amenity-text mt-2">{amenity.name}</span>
              </div>
            </li>
            {index !== amenities.length - 1 && <li className="">&nbsp;</li>}
          </React.Fragment>

        ))}
      </ul>
    </div>

  );
};

export default Amenities;
