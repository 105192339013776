import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { getCurrentTimestampInTimezone, getTimestampFromTimeStringWithTimeZone } from '../common/commonFunction';

interface StopwatchProps {
  basePrice?: string;
  startTime?: string;
  timeZone?: string;
  resetCallback?: () => void;
}
export interface TimerRef {
    resetTimer: () => void;
  }
  const Timer: React.FC<StopwatchProps> = ({ basePrice, startTime, timeZone }) => {
    const [time, setTime] = useState<number>(0);
  const [running, setRunning] = useState<boolean>(true);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (startTime) {
      const startTimestamp = getTimestampFromTimeStringWithTimeZone(startTime); // convert to seconds
           if(startTimestamp !== null){
            console.log((startTimestamp/1000), 'startTimeStamp')
      console.log(Math.floor((getCurrentTimestampInTimezone(timeZone))/1000), 'cts')
      const currenttimestamp = new Date().getTime() / 1000; // Convert milliseconds to seconds

      setTime(Math.floor(currenttimestamp - (startTimestamp/1000)));
      }
    }

    if (running) {
      timer = setInterval(() => {
        setTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [running, startTime]);

  const handleStartStop = () => {
    setRunning(!running);
  };

  const pad = (num: number): string => (num < 10 ? '0' + num : String(num));

  const formatTime = (timeInSeconds: number) => {
    return {
      hours: pad(Math.floor(timeInSeconds / 3600)),
      minutes: pad(Math.floor((timeInSeconds % 3600) / 60)),
      seconds: pad(timeInSeconds % 60),
    };
  };

  const calculateCost = (totalMinutes: number, basePrice: string): any => {
    if (basePrice) {
      const cost = (totalMinutes * parseFloat(basePrice)).toFixed(2);
      return cost;
    }
  };


  return (
    <>
      <div className="row mt-4">
        <div className='zen-page-heading'>
          <h3>Time</h3>
        </div>
        <div className="col-12 text-center">
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <div className='mb-2'><small>Hours</small></div>
              <span className='p-2 border border-1 custom-box-shadow'>{formatTime(time).hours[0]}</span>{' '}
              <span className='p-2 border border-1 custom-box-shadow'>{formatTime(time).hours[1]}</span>
            </div>
            <div className="text-center mx-3">
              <div className='mb-2'><small>Minutes</small></div>
              <span className='p-2 border border-1 custom-box-shadow'>{formatTime(time).minutes[0]}</span>{' '}
              <span className='p-2 border border-1 custom-box-shadow'>{formatTime(time).minutes[1]}</span>{' '}
            </div>
            <div className="text-center">
              <div className='mb-2'><small>Seconds</small></div>
              <span className='p-2 border border-1 custom-box-shadow'>{formatTime(time).seconds[0]}</span>{' '}
              <span className='p-2 border border-1 custom-box-shadow'>{formatTime(time).seconds[1]}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='zen-page-heading'>
          <h3>Cost</h3>
        </div>
        <div className='col-6 mt-2 mx-auto'>
            
          <span className='zen-page-heading p-2 border border-1 custom-box-shadow'>
            <h4>$ {basePrice && calculateCost(Math.floor(time / 60), basePrice)}</h4>
          </span>
        </div>
      </div>
    </>
  );
};

export default Timer;
