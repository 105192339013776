import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { unlockDoor } from '../actions/meetingRoom';
import { toast } from 'react-toastify';

function SlidingCheckbox({ id, unlockKey }: { id: number; unlockKey: string }) {
    const [isChecked, setIsChecked] = useState(true);
console.log(unlockKey, 'key')
  const handleCheckboxChange = () => {
    if (isChecked) {
      console.log(unlockKey, 'componenet')
        unlockDoor(id,unlockKey)
          .then((response:any) => {
            // Handle the response
            setIsChecked(!isChecked);
            toast.success('Unlocked successfully');
            setTimeout(() => {
                setIsChecked(true);
              }, 10000);
    
            // Handle state updates or additional actions as needed
            // Example: dispatch({ type: 'DOOR_UNLOCKED', payload: response });
          })
          .catch((error:any) => {
            toast.error('please try again...');
            // Handle errors, e.g., show an error message to the user
            console.error('Unlock error:', error);
            // Example: dispatch({ type: 'UNLOCK_ERROR', payload: error });
          });
      }
    
  };

  return (
    <div className={`custom-slide-checkbox ${isChecked ? 'checked' : ''}`}>
      <input type="checkbox" id="slide-checkbox" checked={isChecked} onChange={handleCheckboxChange} />
      <label htmlFor="slide-checkbox"></label>
      <FontAwesomeIcon icon={isChecked ? faLock: faUnlock} className={`${isChecked ? 'lock lock-icon' : 'unlock unlock-icon'}`} />
      {/* <FontAwesomeIcon icon={faUnlock} className={`unlock-icon ${isChecked ? 'unlock' : 'lock'}`} /> */}
    </div>
  );
}

export default SlidingCheckbox;
