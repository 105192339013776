import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the styles
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

interface SpecificDurationModalProps {
  isOpen: boolean;
  url: string;
  closeModal: () => void;
}

const SpecificDurationModal: React.FC<SpecificDurationModalProps> = ({ isOpen, closeModal, url }) => {
  const navigate = useNavigate();
  const handleBookNow = () => {
    navigate(url+'/?bookingType=specificDuration&duration='+duration);
  };

  const [duration, setDuration] = useState(0);

  const handleDurationChange = (amount: number) => {
    // Ensure duration is not negative
    if (duration + amount >= 0) {
      setDuration(duration + amount);
    }
  };

  const modalStyle = {
    display: isOpen ? 'block' : 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.5)' /* Change the background color and opacity */

  };

  return (
    <div className={isOpen ? 'modal  fade show' : 'modal fade'} tabIndex={-1} role="dialog" style={modalStyle} data-bs-backdrop="static" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Choose Duration</h5>
            <button type="button" className="btn-close" onClick={closeModal}></button>
          </div>
          <div className="modal-body">
            <div className='col-md-3 mx-auto'>
            <form>
              <div className="input-group">
                
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => handleDurationChange(-1)}
                >
                  -
                </button>
                <input
                  type="number"
                  className="form-control"
                  value={duration}
                  onChange={(e) => setDuration(parseInt(e.target.value) || 1)}
                  min="1"
                />
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => handleDurationChange(1)}
                >
                  +
                </button>
              </div>
            </form>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleBookNow}>
              Save
            </button>
            <button type="button" className="btn btn-secondary" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecificDurationModal;
