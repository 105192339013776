import React from 'react';
const ShimmerMeetingRoomDetails = () => {
  return (
    <div className="row">
      
        <h2>
        <div className="shimmer-title"></div>
        </h2>
        <h5>
        <div className="shimmer-line"></div>
        </h5>
        <small>
        <div className="shimmer-line"></div>
        </small>
        <small>
        <div className="shimmer-line"></div>
        </small>
        <hr />
        <div className="row">
        <div className="shimmer-banner"></div>

          <div className="col-md-6">
            <h2>
            <div className="shimmer-line"></div>
            </h2>
            <ul>
              <li>
              <div className="shimmer-line"></div>
              </li>
              <li>
              <div className="shimmer-line"></div>
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <h2>
            <div className="shimmer-line"></div>
            </h2>
            <ul>
              <li>
              <div className="shimmer-line"></div>
              </li>
            </ul>
          </div>
        </div>
        <br />
      {/* Rest of your code */}
    </div>
  );
};

export default ShimmerMeetingRoomDetails;
