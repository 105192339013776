import React from 'react';
import { MeetingSpace } from '../utils/interfaces/interfaces';
interface BookingSummaryProps {
  data: MeetingSpace;
  bookingType: string;
   // Replace YourDataInterface with the actual type for your 'data' object
}

const BookingSummary: React.FC<BookingSummaryProps> = ({ bookingType, data }) => {
    const pricing = data?.pricing
    return (
        <div className='col-md-12'>
<div className='zen-page-heading mt-5'>
            <h2>{data?.name}</h2>
            {/*         <h3 className='mt-1'>{data?.group}</h3>
          <h3 className='mt-1'>{data?.location}</h3>
          <span className='zen-address-text'>{address?.address_prefix}, {address?.street}, {address?.city}, {address?.state}, {address?.country}, {address?.postal_code}</span> */}
          </div>
          <br />
          <img src={data?.banner_detail?.card_image} className="img-fluid" style={{ 'height': '365px', 'borderRadius': '6px' }} alt="..." />
          <div className='row'>
            <div className='zen-page-heading mt-2'>
              <h4>Booking Summary</h4>
            </div>
            <ul className="list-group booking-summary-list">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Booking Type
                <span className="badge badge-primary badge-pill">{bookingType}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Rate
                <span className="badge badge-primary badge-pill">${bookingType === "fullDay" ? data?.pricing.price_per_minute_day :  data?.pricing.price_per_minute}/min</span>
              </li>
              {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                Total Price
                <span className="badge badge-primary badge-pill">$24</span>
              </li> */}
              {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                Booking Date
                <span className="badge badge-primary badge-pill">13.09.2023</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
                Booking Time
                <span className="badge badge-primary badge-pill">11:00 PM</span>
            </li> */}
            </ul>
          </div>
        </div>
      );
    
};

export default BookingSummary;
