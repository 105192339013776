import React from 'react';
import { MeetingSpace } from '../utils/interfaces/interfaces';
import { calculateDuration, formatDateAndTime } from '../common/commonFunction';

const BookingSummarySuccess: React.FC<any> = ({ data }) => {
    return (
        <div className='col-md-12'>
<div className='zen-page-heading mt-2'>
            <h2>{data?.meeting_room?.name}</h2>
            {/*         <h3 className='mt-1'>{data?.group}</h3>
          <h3 className='mt-1'>{data?.location}</h3>
          <span className='zen-address-text'>{address?.address_prefix}, {address?.street}, {address?.city}, {address?.state}, {address?.country}, {address?.postal_code}</span> */}
          </div>
          <br />
          <img src={data?.meeting_room?.banner_detail?.card_image} className="img-fluid" style={{ 'height': '365px', 'borderRadius': '6px' }} alt="..." />
          <div className='row'>
            <div className='zen-page-heading mt-2'>
              <h4>Booking Summary</h4>
            </div>
            <ul className="list-group booking-summary-list">
              {data?.status==='completed' &&<li className="list-group-item d-flex justify-content-between align-items-center">
                Time Duration
                <span className="badge badge-primary badge-pill">{calculateDuration(data?.start_time, data?.end_time)}</span>
              </li>}
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Rate
                <span className="badge badge-primary badge-pill">${data?.type=== 1 ? data?.meeting_room?.pricing.price_per_minute : data?.meeting_room?.pricing.price_per_minute_day}/min</span>
              </li>
              {data?.status==='completed' &&  <li className="list-group-item d-flex justify-content-between align-items-center">
                Total Price
                <span className="badge badge-primary badge-pill">
  ${data?.type === 1 ? 
    // (data?.meeting_room?.pricing.price_per_minute_day || 0) * (calculateDuration(data?.start_time, data?.end_time) || 0) :
    // (data?.meeting_room?.pricing.price_per_minute || 0) * (calculateDuration(data?.start_time, data?.end_time) || 0)
(data?.booking_amount) : 0
  }
</span>
              </li>}
              <li className="list-group-item d-flex justify-content-between align-items-center">
                Booking Date
                <span className="badge badge-primary badge-pill">{data ? formatDateAndTime(data?.start_time)?.date:''}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
                Booking Time
                <span className="badge badge-primary badge-pill">{data ? formatDateAndTime(data?.start_time)?.time:''}</span>
            </li> 
            </ul>
          </div>
        </div>
      );
    
};

export default BookingSummarySuccess;
