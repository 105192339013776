import React from 'react';

const ShimmerCard = () => {
  return (
    <div className="card shimmer-card bg-light">
      <div className="shimmer-image card-img-top"></div>
      <div className="card-body shimmer-content">
        <div className="shimmer-title"></div>
        <div className="shimmer-description"></div>
      </div>
    </div>
  );
};

export default ShimmerCard;
