import React from 'react';
import ImageSlider from './Slider';
import { Map } from 'react-bootstrap-icons';
import { wrapText } from '../common/commonFunction';

interface Address {
  id: number;
  address_prefix: string;
  country: string;
  street: string;
  city: string;
  state: string;
  postal_code: string;
  address_suffix: string;
}

interface CardProps {
  title: string;
  address: Address;
  card_image: string;
  group: boolean;
}

  const Card = ({ title, address, card_image, group }: CardProps) => {
    const images = [ card_image  ];

  return (
    <div className="card zen-card">
      <div className="card-header" >
        <div className='row'>
          <div className='col-9'>
          <h5 className="zen-card-heading">{group ? title: wrapText(title,15)}</h5>

          </div>
          <div className='col-3'>
          { !group &&
        <a className='btn btn-small zen-card-header-icon' style={{ marginLeft: "auto" }}>
          <img src="/images/icon-building.svg" alt="map-icon"/>
          {/* &nbsp;&nbsp;Hotel */}
        </a>
        }
          </div>
        </div>
         
        </div>
      <ImageSlider images={images} />
      <div className="card-body">
        <div className="d-flex align-items-center">
          <img src="/images/map.svg" alt="map-icon"/>&nbsp;&nbsp;
          <p className="card-text zen-card-text">{address.address_prefix},{address.street},{address.city},{address.state},{address.country},{address.postal_code}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
