import React from 'react';

interface ShimmerAmenitiesProps {
  count: number;
}

const ShimmerAmenities = ({ count }: ShimmerAmenitiesProps) => {
  const shimmerItems = Array.from({ length: count }, (_, index) => (
    <React.Fragment key={index}>

    <li className="list-group-item shimmer-line"></li>
     <li className=""></li>&nbsp;
</React.Fragment>
  ));

  return (
    <div className="amenities">
      <h2>Amenities:</h2>
      <ul className="list-group list-group-horizontal">{shimmerItems}</ul>
    </div>
  );
};

export default ShimmerAmenities;
