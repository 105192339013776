import React, { useState } from 'react';
import ImageSlider from './Slider';
import { Map } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { getMeetingRoomType, wrapText } from '../common/commonFunction';
import { InUseStatus, AvailableStatus, UnavailableStatus } from './Status';
import { useDispatch, useSelector } from 'react-redux';
import UserDetailsModal from './UserDetailsModal'
import Tags from './Tags';
import { Amenity } from '../utils/interfaces/interfaces';
interface Address {
  id: number;
  address_prefix: string;
  country: string;
  street: string;
  city: string;
  state: string;
  postal_code: string;
  address_suffix: string;
}
interface Pricing{
  "id": number,
"name": string
"price_per_minute": string,
"price_per_minute_day": string,
"stripe_price_per_minute_id": null,
"stripe_price_per_minute_day_id": null,
"is_active": boolean,
}

interface CardProps {
  title: string;
  address: Address;
  card_image: string;
  url: string;
  type: number;
  status: number;
  amenities: Amenity[];
  pricing: Pricing;
  onBookNowClick: (url: string) => void; // Update the function signature
  onOptionChange:(option:string)=> void;
}


// Import React and other necessary modules

const MeetingCard = ({ title, address, card_image, amenities, url, type, status, pricing, onOptionChange, onBookNowClick }: CardProps) => {
  const [selectedOption, setSelectedOption] = useState('');

  return (
    <div className="card border-bottom border-0">
      <div className="row mt-3 mb-2">
        <div className="col-md-3 col-sm-3">
          <img src={card_image} className="img-fluid" style={{ height: '169px' }} alt="..." />
        </div>
        <div className="col-md-9 col-sm-9 rounded-0">
          <div className="card-body">
            <div className="row d-flex">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className='row'>
                    <h5 className="card-title zen-card-heading">{wrapText(title, 20)}</h5>
                  </div>
                  <div className='row'>
                  <div>
                    {status === 1 && <InUseStatus />}
                    {status === 2 && <AvailableStatus />}
                    {status === 3 && <UnavailableStatus />}
                    {(status !== 1 && status !== 2 && status !== 3) && 'UNKNOWN'}
                  </div>
                  </div>

                  </div>
                
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
              <div className="row">
  <div className="text-center">
    <p className="font-weight-bold mb-0">Pay as you go</p>
  </div>
  <div className="text-center py-3">
    <p className="text-primary mb-0">{`$${pricing.price_per_minute}/minute`}</p>
  </div>
</div>


              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                <div className="row">
                  {amenities.map((amenity: any) => (
                    <div className="col-md-3 mt-3" key={amenity.name}>
                      <Tags name={amenity.name} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-3">
                {status === 2 && (
                  <button
                    onClick={() => onBookNowClick(url)}
                    className="btn btn-outline-primary mt-3 zen-book-btn"
                  >
                    Book
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingCard;
