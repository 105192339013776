import React, { useEffect, useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { PaymentMethodResult, CreatePaymentMethodData, loadStripe } from '@stripe/stripe-js';
import { createBookingAction } from '../actions/reservation';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { PaymentRequest } from '@stripe/stripe-js'; // Import PaymentRequest from stripe-js

// Replace 'your_publishable_key' with your actual Stripe Publishable Key.
const stripePromise = loadStripe('pk_live_CEKEZh124pleOeee47rAXnGB');

const PaymentForm = (props:any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethodId, setPaymentMethodId] = useState<any>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  // useEffect(() => {
  //   if (stripe) {
  //     const pr = stripe.paymentRequest({
  //       country: 'US',
  //       currency: 'usd',
  //       total: {
  //         label: 'Demo total',
  //         amount: 1099,
  //       },
  //       requestPayerName: true,
  //       requestPayerEmail: true,
  //     });
  //     setPaymentRequest(pr);

  //     // Check the availability of the Payment Request API.
  //     pr.canMakePayment().then(result => {
  //       if (result) {
  //         setPaymentRequest(pr);
  //       }
  //     });
  //   }
  // }, [stripe]);
  const handleSubmit = async (event:any) => {
    event.preventDefault();
    setIsLoading(true)

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      console.error('Card Number Element not found');
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        // You can add additional billing details here if needed
      },
    });

    if (error) {
      setIsLoading(false)
      toast.error(error.message)
       console.error(error);
    } else {
      // PaymentMethod ID is now available
      if(paymentMethod){
        const guestData = sessionStorage.getItem('guest');
        console.log(guestData, 'guestData')
        const bookingData = {
          guest: guestData && JSON.parse(guestData),
          meeting_room: parseInt(props?.id), // Assuming 'id' is the meeting_room id
          type: 1,
          payment_method_id: paymentMethod.id
          //booking_amount: getAmount(bookingType,data?.start_time, data?.end_time,duration, price)
          //booking_amount: price
        };
    console.log(bookingData, 'bookingData')
      setPaymentMethodId(paymentMethod.id);
      const response = await createBookingAction(bookingData);
      console.log(response, 'response')
      // Assuming your API returns a success status
      if (response.status === 201) {
        setIsLoading(false)

        toast.success('reservation successfully done..');

        // window.location.href = response?.data?.payment_url

        // Navigate to success page
       navigate(`/reservation_success/${response.data.id}`);
      } else {
        setIsLoading(false)

        // Handleq booking failure
        // Display error message or take necessary action 
        // Show error toast
         toast.error('Booking failed. Please try again.');
      }

      }
      // You can send this paymentMethod ID to your server for further processing
    }
  };


  return (
    <>
    {/* {paymentRequest !==null &&
     <PaymentRequestButtonElement
        className="PaymentRequestButton"
    options= {{paymentRequest}}
     
      />
    } */}
    <form className="payment-form" onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="cardNumber">Card number</label>
      <CardNumberElement id="cardNumber" className="stripe-input" />
    </div>
    <div className="form-group">
      <label htmlFor="cardExpiry">Expiration date</label>
      <CardExpiryElement id="cardExpiry" className="stripe-input" />
    </div>
    <div className="form-group">
      <label htmlFor="cardCvc">CVC</label>
      <CardCvcElement id="cardCvc" className="stripe-input" />
    </div>
    <button type="submit" disabled={!stripe} className="pay-button">
    {isLoading ? <Loader/> :
'Pay Now'}
    </button>
  </form>
    </>
  );
};

export default PaymentForm;