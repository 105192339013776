import React from 'react';
import { Route, Routes } from 'react-router-dom';

// Import your views for each route
import Home from './views/Home';
import Location from './views/Location';
import Group from './views/Group';
import MeetingRoom from './views/MeetingRoom';
import ConfirmBooking from './views/ConfirmBooking';
import Payment from './views/Payment';
import ReservationSuccess from './views/ReservationSuccess';

const AppRoute = () => {
  return (
    <Routes>
      {/* Define your routes */}
      <Route path="/" element={<Home />} />
      <Route path="/:locationSlug" element={<Location />} />
      <Route path="/:locationSlug/:groupSlug" element={<Group />} />
      <Route path="/:locationSlug/:groupSlug/:meetingRoomSlug" element={<MeetingRoom />} />
      <Route path="/confirm_booking/:id" element={<ConfirmBooking />} />
      <Route path="/make_payment/:id" element={<Payment />} />
      <Route path="/reservation_success/:id" element={<ReservationSuccess />} />
    </Routes>
  );
};

export default AppRoute;
