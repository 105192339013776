import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './utils/store'; // Import your Redux store
import Footer from './components/Footer';
import Header from './components/Header';
import AppRoute from './AppRoute';
import './App.css'; // Import the CSS file
const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Header />
        <AppRoute />
        <Footer />
      </Router>
    </Provider>
  );
}

export default App;
