import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { combineReducers } from 'redux';
import locationsReducer from './slices/locationsSlice';
import groupsReducer from './slices/groupsSlice';
import meetingRoomsReducer from './slices/meetingSpaceSlice';
import  useReducer from './slices/userSlice';

const rootReducer = combineReducers({
  locations: locationsReducer,
  groups: groupsReducer,
  meetingRooms: meetingRoomsReducer,
  user: useReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkMiddleware),
});

export default store;
