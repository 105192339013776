import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { fetchLocationsAction } from '../actions/locations';
import Card from '../components/Card';
import ShimmerCardList from '../components/ShimmerCardList';
import Filters from '../components/Filters';
import { Link } from 'react-router-dom';

const Home = () => {
  const dispatch = useDispatch<Dispatch>();
  const { loading, list_data: locations } = useSelector((state:any) => state.locations);

  useEffect(() => {
    console.log(locations);
    dispatch(fetchLocationsAction() as any); // Type assertion to any
  }, [dispatch]);

  return (
    <div className="container content-body">
      <div className="row">
        <div className='zen-page-heading'>
          <h1>Locations</h1>
        </div>
        {/* <div className='row'>
          <Filters/>
        </div> */}
        <div className='row'>
        {loading ? (
          // Render shimmer cards when data is loading
          <ShimmerCardList length={6} />
        ) : (
          // Render actual data when loaded
          locations.map((location:any) => (
            <div className="col-md-4" key={location.id}>
             <Link to={`/${location.access.url_slug}`} style={{ textDecoration: 'none' }}>

              <Card title={location.name} address={location.venue_details.address} card_image={location.banner_detail.card_image} group={false} />
            </Link>
            </div>
          ))
        )}
        </div>

      </div>
    </div>
  );
};

export default Home;
