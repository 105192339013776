import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationByIdAction } from '../actions/locations';
import { useParams, Link } from 'react-router-dom';
import Banner from '../components/Banner';
import Amenities from '../components/Ameneties';
import Card from '../components/Card';
import ShimmerLocationDetail from '../components/ShimmerLocationDetail';
import { wrapText } from '../common/commonFunction';
import Filters from '../components/Filters';


const Location = () => {
  const { locationSlug } = useParams();
  const dispatch = useDispatch();
  const { loading, data: location } = useSelector((state: any) => state.locations);

  useEffect(() => {
    console.log("locationSlug:", locationSlug); // Add this line
    if (locationSlug) {
      const locationId = locationSlug; // Convert id to a number
      dispatch(fetchLocationByIdAction(locationId) as any);
    }
  }, [dispatch, locationSlug]);

  return (
    <div className="container content-body">
      {loading ? (
        <ShimmerLocationDetail/>
      ) : location ? ( // Check if location exists before accessing its properties
        <>
        <div className='row'>

        <div className='zen-page-heading'>
          <h1>{wrapText(location.name,20)}</h1>
          <span className='zen-address-text'>{location?.venue_details?.address?.address_prefix},{location?.venue_details?.address?.street},{location?.venue_details?.address?.city},{location?.venue_details?.address?.postal_code},{location?.venue_details?.address?.state},{location?.venue_details?.address?.country}</span>
        </div>
        </div>
    
        <Banner image={location.banner_detail.banner_url}/>
          <br/>
          <p className='zen-location-description'>{location.description}</p>
          
          <div className="row">
          <div className='zen-page-heading'>
          <h2>Groups</h2>
        </div>
        {/* <div className='row'>
          <Filters/>
        </div> */}
                  {location.groups.map((group:any) => (
            <div className="col-md-4" key={group.id}>
             <Link to={`/${location.access.url_slug}/${group.access.url_slug}/`} style={{ textDecoration: 'none' }}>

              <Card title={group.name} address={group.venue_details.address} card_image={group.banner_detail.card_image} group={true} />
            </Link>
            </div>
          ))}
        
      </div>
      <div className='row'>
          <Amenities amenities={location.amenities}/>
          </div>
        </>
      ) : (
        <p>No location found.</p>
      )}
    </div>
  );
};

export default Location;
