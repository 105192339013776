import React from 'react';

const Footer = () => {
  return (
<footer className="bg-light py-4">
    <div className="container zen-footer-container">
        <div className="row text-center">
            <div className="col-md-3">
                <img src="/images/zenspace-logo.svg" alt="Logo"/>
            </div>
            <div className="col-md-9">
                <ul className="nav justify-content-center mb-4 zen-footer-list">
                    <li className="nav-item">
                        <a href="#" className="nav-link text-muted">Home</a>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link text-muted">Contact</a>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link text-muted">Terms of Use</a>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link text-muted">Cookies and Privacy Policies</a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="row text-center">
            <div className="col-md-4">
                <ul className="nav mb-4">
                    <li className="nav-item">
                        <a href="#" className="nav-link text-muted">
                            <div className="zen-social-icon">
                                <img src="/images/facebook-f.svg" alt="Facebook Logo"/>
                            </div>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link text-muted">
                            <div className="zen-social-icon">
                                <img src="/images/instagram.svg" alt="Instagram Logo"/>
                            </div>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link text-muted">
                            <div className="zen-social-icon">
                                <img src="/images/linked-in-2.svg" alt="LinkedIn Logo"/>
                            </div>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#" className="nav-link text-muted">
                            <div className="zen-social-icon">
                                <img src="/images/you-tube.svg" alt="YouTube Logo"/>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="col-md-4 zen-footer-text">
                <img src="/images/phone.svg" alt="Phone Logo" style={{width: "24px"}}/>
                &nbsp;&nbsp;&nbsp; +1 (415) 993-6772
            </div>
            <div className="col-md-4">
                <p className="zen-footer-text">© 2023 by ZenSpace</p>
            </div>
        </div>
    </div>
</footer>

  );
};

export default Footer;
