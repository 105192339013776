import React, { useState, useEffect } from 'react';
import { fetchMeetingSpaceByIdAction } from '../actions/meetingRoom';
import { createBookingAction } from '../actions/reservation';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the styles
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTimeInTimeZone } from '../common/commonFunction';
import BookingSummary from '../components/BookingSummary';
import { toast } from 'react-toastify';

const ConfirmBooking = () => {
  const [name, setName] = useState('');
  const [startTime, setStatrtTime] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const navigate = useNavigate();
  const path = useLocation();
  const queryParams = new URLSearchParams(path.search);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state: any) => state.meetingRooms);

  // Access bookingType and duration query parameters
  const bookingType = queryParams.get('bookingType');
  const duration = queryParams.get('duration');
  const end_time = queryParams.get('endtime');
  const isValidEmail = (email: string) => {
    // Regular expression for a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handlePhoneChange = (value: string) => {
    // Set the formatted phone number
    setPhone('+' + value);
  };

  const getAmount = (bookingType: any, start_time: any, end_time: any, duration: any, price:any) => {
    console.log(price, 'price')
    const numericPrice = typeof price === 'number' ? price : parseFloat(price);

    if(bookingType === 'fullDay'){
    const starttime = new Date(start_time);
    const endtime = new Date(end_time);

    if (starttime instanceof Date && endtime instanceof Date) {
      const timeDifferenceMs = endtime.getTime() - starttime.getTime();
      const timeDifferenceMinutes = timeDifferenceMs / (1000 * 60);
      const amount = numericPrice * timeDifferenceMinutes
      console.log("Total time in minutes amount: " + amount);
      return  amount.toFixed(2)

    } else {
      console.error("Invalid Date objects");
    }
  }else if(bookingType ==='specificDuration'){
    
    const totalMinutes = duration*60;
   const amount = numericPrice * totalMinutes
    return  amount.toFixed(2)
  }
  }

  const getEndTime = (duration: any, timezone:any)=>{
    const currentDateTime = new Date(getCurrentTimeInTimeZone(timezone)); // Get the current time in the specified timezone
    const hoursToAdd = typeof duration === 'number' ? duration : parseInt(duration, 10);

    if (isNaN(hoursToAdd)) {
      console.error("Invalid duration");
      return null; // Handle the case of an invalid duration.
    }
  
    // Define the number of hours to add
    
    // Calculate the new date by adding the hours
    console.log(hoursToAdd, 'duration')
    currentDateTime.setHours(currentDateTime.getHours() + hoursToAdd);
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
    const day = String(currentDateTime.getDate()).padStart(2, '0');
    const hours = String(currentDateTime.getHours()).padStart(2, '0');
    const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
    const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');
  
    // Construct the formatted end time
    const formattedEndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
    console.log(currentDateTime, 'currentTime');
    console.log(formattedEndTime, 'endtime');
    return formattedEndTime;
  
  }
  const handleBookNow = async () => {
    if (bookingType === 'fullDay' || bookingType === "specificDuration") {

    if (name && isValidEmail(email) && phone_number && id) {
      const guestData = { name, email, phone_number };
      sessionStorage.setItem('guest', JSON.stringify(guestData));
      //navigate();
      console.log(bookingType, 'bookingData')
      const price = bookingType === "fullDay" || bookingType === "specificDuration" ? data?.pricing.price_per_minute_day : data?.pricing.price_per_minute
      const bookingData = {
        guest: guestData,
        meeting_room: parseInt(id), // Assuming 'id' is the meeting_room id
        end_time: bookingType === 'fullDay' ? data?.end_time : getEndTime(duration, data?.timezone),
        type: bookingType === 'fullDay' ? 3 : 2,
        booking_amount: getAmount(bookingType,data?.start_time, data?.end_time,duration, price)
        //booking_amount: price
      };

      // Dispatch the createBookingAction
      const response = await createBookingAction(bookingData);
      console.log(response, 'response')
      // Assuming your API returns a success status
      if (response.status === 201) {
        toast.success('Redirecting to payment link..');

        window.location.href = response?.data?.payment_url

        // Navigate to success page
//        navigate(`/reservation_success/${response.data.id}`);
      } else {
        // Handleq booking failure
        // Display error message or take necessary action 
        // Show error toast
         toast.error('Booking failed. Please try again.');
      }

    } else {
      if (!isValidEmail(email)) {
        setEmailError('Please enter a valid email address.');
      }
      if (!phone_number) {
        setPhoneError('Please enter a phone number.');
      }
    }
    }else{
      const guestData = { name, email, phone_number };
      sessionStorage.setItem('guest', JSON.stringify(guestData));

      navigate(`/make_payment/${id}/?bookingType=payAsYouGo`);

    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchMeetingSpaceByIdAction({ meetingroomId: id ?? '' }) as any);
    }
  }, [id]);
  return (
    <div className="container content-body">
      <div className='row'>
        <div className='col-md-8'>

          <div className='row'>
            <div className='col-md-9 mx-auto'>
              <div className='zen-page-heading mt-5'>
                <h2>Complete your Reservation</h2>
                {/* <h3 className='mt-1'>{location?.groups[0]?.name}</h3>
          <h3 className='mt-1'>{location?.name}</h3>
          <span className='zen-address-text'>{address?.address_prefix}, {address?.street}, {address?.city}, {address?.state}, {address?.country}, {address?.postal_code}</span> */}
              </div>
              {/* <div className='row mt-5'>
        <div className='col-md-4'><Tags name="Available on Weekends"/></div>
        <div className='col-md-4'><Tags name="Late Night Hours"/></div>
        </div> */}

              <form>
                <div className="mb-3">
                  <label className="form-label">Email*:</label>
                  <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <div className="text-danger">{emailError}</div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Name:</label>
                  <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div className="mb-3">
                  <label className="form-label">Phone Number:</label>
                  <PhoneInput
                    country={'us'} // Set the default country
                    value={phone_number}
                    onChange={handlePhoneChange}
                  />
                  <div className="text-danger">{phoneError}</div>
                </div>
              </form>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-5 mx-auto'>
              <button type="button" className="btn btn-outline-primary mt-5 zen-book-btn" onClick={handleBookNow}>Next</button>

            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <BookingSummary data={data} bookingType={bookingType ? bookingType : ''}/>

        </div>
      </div>
    </div>
  );
};

export default ConfirmBooking;
