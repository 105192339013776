import React from 'react';
interface BannerProps{
    image: string;
  }
const Banner = ({image}: BannerProps) => {
  return (
    <div className="banner mt-3">
      <img src={image} alt="Banner" className="banner-image" />
    </div>
  );
};

export default Banner;
