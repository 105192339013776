import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from '../interfaces/interfaces'; // Import the Location interface from the separate file

interface LocationsState {
  list_data: Location[];
  data: Location | null; // Update the type to Location | null
  loading: boolean;
  error: string | null;
}

const initialState: LocationsState = {
  list_data: [],
  data: null, // Set the initial value to null
  loading: false,
  error: null,
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    fetchLocationsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchLocationsSuccess(state, action: PayloadAction<Location[]>) {
      state.list_data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchLocationsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    fetchLocationStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchLocationSuccess(state, action: PayloadAction<Location>) {
      console.log(action.payload)
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchLocationFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchLocationsStart,
  fetchLocationsSuccess,
  fetchLocationsFailure,
  fetchLocationStart,
  fetchLocationSuccess,
  fetchLocationFailure,
} = locationsSlice.actions;

export default locationsSlice.reducer;
