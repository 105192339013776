import React from 'react';
import ShimmerCard from './ShimmerCard';
interface size {
   length: number
  }
const ShimmerCardList = ({ length }:size) => {
  const shimmerCards = Array.from({ length }, (_, index) => (
    <div className="col-md-4" key={index}>
      <ShimmerCard />
    </div>
  ));

  return <div className="row">{shimmerCards}</div>;
};

export default ShimmerCardList;
