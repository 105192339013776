import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLocations, fetchLocationBySlug, fetchLocationGroupBySlug, fetchLocationGroupMeetingBySlug, createBooking } from '../services/locationServices';
import { fetchLocationsStart, fetchLocationsSuccess, fetchLocationsFailure, fetchLocationStart, fetchLocationSuccess, fetchLocationFailure } from '../utils/slices/locationsSlice';

export const fetchLocationsAction = createAsyncThunk<void, void>(
  'locations/fetch',
  async (_, { dispatch }) => {
    try {
      dispatch(fetchLocationsStart());
      const locations = await fetchLocations();
      dispatch(fetchLocationsSuccess(locations));
    } catch (error: any) {
      dispatch(fetchLocationsFailure(error.message));
    }
  }
);

export const fetchLocationByIdAction = createAsyncThunk(
  'location/fetchBySlug',
  async (id: string, { dispatch }) => {
    try {
      dispatch(fetchLocationStart());
      const location = await fetchLocationBySlug(id);
      dispatch(fetchLocationSuccess(location));
    } catch (error: any) {
      dispatch(fetchLocationFailure(error.message));
    }
  }
);

export const fetchLocationGroupByIdAction = createAsyncThunk(
  'location/group/fetchBySlug',
  async ({ locationSlug, groupSlug }: { locationSlug: string; groupSlug: string }, { dispatch }) => {
    try {
      dispatch(fetchLocationStart());
      const location = await fetchLocationGroupBySlug(locationSlug, groupSlug);
      dispatch(fetchLocationSuccess(location));
    } catch (error: any) {
      dispatch(fetchLocationFailure(error.message));
    }
  }
);

export const fetchLocationGroupMeetingSpaceByIdAction = createAsyncThunk(
  'location/group/meetingspace/fetchBySlug',
  async ({ locationSlug, groupSlug, meetingroomSlug }: { locationSlug: string; groupSlug: string, meetingroomSlug: string }, { dispatch }) => {
    try {
      dispatch(fetchLocationStart());
      const location = await fetchLocationGroupMeetingBySlug(locationSlug, groupSlug, meetingroomSlug);
      console.log(location)
      dispatch(fetchLocationSuccess(location));
    } catch (error: any) {
      dispatch(fetchLocationFailure(error.message));
    }
  }
);


