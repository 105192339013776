import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocationGroupMeetingSpaceByIdAction } from '../actions/locations';
import { createBookingAction } from '../actions/reservation';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getMeetingRoomType, convertTimeToISOString } from '../common/commonFunction';
import Banner from '../components/Banner';
import Amenities from '../components/Ameneties';
import { GeoAlt } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import ShimmerMeetingRoomDetails from '../components/ShimmerMeetingRoomDetails';
import Tags from '../components/Tags';
import { wrapText } from '../common/commonFunction';


const MeetingRoom = () => {
  const navigate = useNavigate();
  const [pricingType, setPricingType] = useState(0); // Default: 'wholeDay'
  const { locationSlug, groupSlug, meetingRoomSlug } = useParams();
  const dispatch = useDispatch();
  const { loading, data: location } = useSelector((state: any) => state.locations);
  const path = useLocation();
  const queryParams = new URLSearchParams(path.search);

  // Access bookingType and duration query parameters
  const bookingType = queryParams.get('bookingType');
  const duration = queryParams.get('duration');
console.log(bookingType, duration)
  useEffect(() => {
    if (groupSlug) {
      const groupId = groupSlug; // Convert id to a number
      const meetingSlug = meetingRoomSlug;
      dispatch(fetchLocationGroupMeetingSpaceByIdAction({ locationSlug: locationSlug ?? '', groupSlug, meetingroomSlug: meetingSlug ?? '' }) as any);
    }
  }, [meetingRoomSlug]);
  const address = location?.venue_details?.address;
  const onConfirmBooking = async (id: string, end_time: string) => {
    // try {
    //   const guest = sessionStorage.getItem('guest');
    //   if (guest) {
    //     const guestData = JSON.parse(guest);

    //     // Prepare the booking data, including guest data and meeting_room id
    //     const bookingData = {
    //       guest: guestData,
    //       meeting_room: id, // Assuming 'id' is the meeting_room id
    //       end_time: pricingType === 0 ? end_time : ''
    //     };

    //     // Dispatch the createBookingAction
    //     const response = await createBookingAction(bookingData);
    //     console.log(response, 'response')
    //     // Assuming your API returns a success status
    //     if (response.status === 201) {
    //       // Navigate to success page
    //       navigate(`/reservation_success/${response.data.id}`);
    //     } else {
    //       // Handle booking failure
    //       // Display error message or take necessary action 
    //       // Show error toast
    //       toast.error('Booking failed. Please try again.');
    //     }
  
    //   }
    // } catch (error: any) {
    //   console.error('Error confirming booking:', error.message);
    // }
      navigate(`/confirm_booking/${id}/?bookingType=${bookingType}&end_time=${end_time}&duration=${duration}`);

  };

  return (
    <div className="container content-body">
      {loading ? (
          // Render shimmer cards when data is loading
            <ShimmerMeetingRoomDetails  />   
                     ) : (
      <div className='row'>
        <div className='col-md-9'>
        <div className='zen-page-heading '>
          <h1>{location?.groups[0]?.meeting_rooms[0]?.name}</h1>
          <h3 className='mt-1'>{location?.groups[0]?.name}</h3>
          <h3 className='mt-1'>{location?.name}</h3>
          <span className='zen-address-text'>{address?.address_prefix}, {address?.street}, {address?.city}, {address?.state}, {address?.country}, {address?.postal_code}</span>
        </div>
        <div className='row mt-5'>
        <div className='col-md-4'><Tags name="Available on Weekends"/></div>
        <div className='col-md-4'><Tags name="Late Night Hours"/></div>
        </div>
          <div className='row'>
          <div className='zen-page-heading mt-2'>
              <h3>Booking Requirements</h3>
              </div>
              <div>
                <ul>
                <li>The minimum booking duration is 30 minutes.</li>
                <li>Your booking will be confirmed immediately once you book it.</li>
                </ul>
              </div>
              
              <div className='row'>
              <div className='zen-page-heading mt-2'>
                <h3>Cancellation Policy</h3>
                </div>
                <div>
                  <ul><li>No cancellation allowed.</li></ul>
                </div>
              </div>
              <div className='row'>
                <Amenities amenities={location?.groups[0]?.meeting_rooms[0]?.amenities} />
              </div>
              <br />

              
          {/* <div className='col-6'>
            <div className="d-flex justify-content-center mt-2">
          <label className="switch">
            <input type="checkbox" onChange={() => setPricingType(pricingType === 0 ? 1 : 0)} />
            <span className="slider round"></span>
          </label>
          <span className="ms-2">{pricingType === 0 ? 'Full Day Booking' : 'Pay-as-You-Go'}</span>
        </div>
                <ul className="list-group">
                  <div>
                    <h3>Pricing</h3>
                     
                  </div>
                  
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Sales price
                    <span className="badge bg-primary rounded-pill">$ 0</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Service tax
                    <span className="badge bg-primary rounded-pill">$ 0</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Amount due
                    <span className="badge bg-primary rounded-pill">$ 0</span>
                  </li>
                </ul>
                <br />
                
              </div> */}
          
          </div>
          </div>
          <div className='col-md-3'>
          <img src={location?.groups[0]?.meeting_rooms[0]?.banner_detail?.card_image} className="img-fluid" style={{ 'height': '365px', 'borderRadius': '6px' }} alt="..." />
          <div className='row'>
          <div className='zen-page-heading mt-2'>
                <h4>Booking Summary</h4>
                </div>
          <div className='col-md-6'>Booking Type:</div>
          <div className='col-md-6'>{bookingType}</div>
          <div className='col-md-6'>Rate:</div>
          <div className='col-md-6'>{location?.groups[0]?.meeting_rooms[0]?.pricing?.price_per_minute}</div>
          </div>
          <button className="btn btn-outline-primary mt-5 zen-book-btn"
                onClick={() => onConfirmBooking(location?.groups[0]?.meeting_rooms[0]?.id, location?.groups[0]?.meeting_rooms[0].end_time)}
                >
                  Confirm Booking
                </button>
</div>
              </div>
              )}
        </div>
        );
};

        export default MeetingRoom;
