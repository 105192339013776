import {createBooking, getBooking, updateBooking } from '../services/locationServices';

export const createBookingAction = async (bookingData:any) => {
      try {
        // You can dispatch an action here to show loading or success status if needed
        const bookingResponse = await createBooking(bookingData);
        
        // You can dispatch an action here to handle booking success or error if needed
        return bookingResponse;
      } catch (error:any) {
        // You can dispatch an action here to handle booking failure if needed
        throw new Error(error.message);
      }
    }
    export const updateBookingAction = async (id:string,bookingData?:any) => {
      try {
        // You can dispatch an action here to show loading or success status if needed
        const bookingResponse = await updateBooking(id);
        
        // You can dispatch an action here to handle booking success or error if needed
        return bookingResponse;
      } catch (error:any) {
        // You can dispatch an action here to handle booking failure if needed
        throw new Error(error.message);
      }
    }
  export const getBookingAction = async (id:any) => {
      try {
        // You can dispatch an action here to show loading or success status if needed
        const bookingResponse = await getBooking(id);
        
        // You can dispatch an action here to handle booking success or error if needed
        return bookingResponse;
      } catch (error:any) {
        // You can dispatch an action here to handle booking failure if needed
        throw new Error(error.message);
      }
    }    