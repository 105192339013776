import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeetingSpace } from '../interfaces/interfaces'; // Import the Location interface from the separate file



interface MeetingSpacesState {
  data: MeetingSpace | null;
  loading: boolean;
  error: string | null;
}

const initialState: MeetingSpacesState = {
  data: null,
  loading: false,
  error: null,
};

const meetingSpaceSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    fetchMeetingSpacesStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchMeetingSpacesSuccess(state, action: PayloadAction<MeetingSpace>) {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchMeetingSpacesFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchMeetingSpacesStart,
  fetchMeetingSpacesSuccess,
  fetchMeetingSpacesFailure,
} = meetingSpaceSlice.actions;

export default meetingSpaceSlice.reducer;
