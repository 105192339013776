import React from 'react';
interface size {
    length: number
   }
const ShimmerMeetingCardList = ({ length }: size) => {
  const shimmerCards = Array.from({ length }, (_, index) => (
    <div className="shimmer-meeting-card" key={index}>
      <div className="shimmer-image"></div>
      <div className="shimmer-content">
        <div className="shimmer-title"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-text"></div>
        <div className="shimmer-button"></div>
      </div>
    </div>
  ));

  return <div className="meeting-card-list">{shimmerCards}</div>;
};

export default ShimmerMeetingCardList;
